import React from 'react';
import Navbar from './components/Navbar/Navbar';
import MainContent from './components/MainContent/MainContent';
import Footer from './components/MainContent/Footer';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App" id="home">
      <Navbar />
      <MainContent />
      <Footer />
    </div>
  );
};

export default App;
