import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="footer-wrapper">
      <p>&copy; 2024 Morph Technologies. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
