import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import './surveyModal.css';

interface SurveyModalProps {
  show: boolean;
  onHide: () => void;
}

const SurveyModal: React.FC<SurveyModalProps> = ({ show, onHide }) => {
  const roles = ['Inspector', 'Company Owner', 'Apprentice', 'Other'];
  const experienceLevels = [
    'Less than 1 year',
    '1-2 years',
    '3-5 Years',
    '6-10 Years',
    '11 Years+',
  ];

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="survey-modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Share Your Background</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          {/* Name Fields */}
          <div className="d-flex gap-3 mb-3">
            <Form.Group className="flex-grow-1">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" />
            </Form.Group>

            <Form.Group className="flex-grow-1">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
          </div>

          {/* Phone and State */}
          <div className="d-flex gap-3 mb-3">
            <Form.Group className="flex-grow-1">
              <Form.Label>Phone</Form.Label>
              <Form.Control type="tel" />
            </Form.Group>

            <Form.Group className="flex-grow-1">
              <Form.Label>State</Form.Label>
              <Form.Select>
                <option value="">Select</option>
                {/* Add state options */}
              </Form.Select>
            </Form.Group>
          </div>

          {/* Role Selection */}
          <Form.Group className="mb-3">
            <Form.Label>Role</Form.Label>
            <div className="d-flex gap-4">
              {roles.map((role) => (
                <Form.Check
                  key={role}
                  type="radio"
                  name="role"
                  label={role}
                  id={`role-${role}`}
                />
              ))}
            </div>
          </Form.Group>

          {/* Experience Level */}
          <Form.Group className="mb-3">
            <Form.Label>Inspection Experience</Form.Label>
            <div className="d-flex gap-3 flex-wrap">
              {experienceLevels.map((level) => (
                <Form.Check
                  key={level}
                  type="radio"
                  name="experience"
                  label={level}
                  id={`exp-${level}`}
                />
              ))}
            </div>
          </Form.Group>

          {/* Company and Tools */}
          <div className="d-flex gap-3 mb-4">
            <Form.Group className="flex-grow-1">
              <Form.Label>Company Name</Form.Label>
              <Form.Control type="text" />
            </Form.Group>

            <Form.Group className="flex-grow-1">
              <Form.Label>Current Inspection Tools</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-end">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SurveyModal;
