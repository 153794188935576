import React from 'react';
import { Container } from 'react-bootstrap';
import './aboutUs.css';

const About = () => {
  return (
    <section className="about" id="about">
      <Container>
        <h2 className="about-title">About Morph Technologies</h2>
        <div className="about-content">
          <p className="about-text">
            We are a tech-driven company dedicated to revolutionizing the
            residential building inspection industry. Our team is comprised of
            professionals with backgrounds in civil engineering, architecture,
            and software engineering. Our founders have firsthand experience in
            the industry and have witnessed the inefficiencies in traditional
            inspection reporting.
          </p>

          <p className="about-text">
            Our mission is to simplify and streamline the inspection process by
            leveraging AI technology, making the work of residential building
            inspectors more efficient. Our AI-assisted mobile app is designed
            for real-time, on-site report generation, reducing the need for
            extensive post-inspection office work. We focus on practicality,
            offering features such as voice-to-text functionality and smart
            pre-built templates to enhance the speed and accuracy of report
            generation.
          </p>

          <p className="about-text">
            Our company culture values flexibility, product ownership, and
            collaborative teamwork. We believe that with our deep industry
            expertise and focus on user needs, our product can truly improve the
            workflow of inspectors.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default About;
