import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import AppIcon from '../../../assets/images/app-icon.png'; // Add your app icon
import './joinWaitlist.css';
import SurveyModal from '../SurveyModal/SurveyModal';
const JoinWaitlist = () => {
  const [showSurvey, setShowSurvey] = useState(false);

  return (
    <section className="join-waitlist" id="join-waitlist">
      <Container>
        <div className="waitlist-container">
          <div className="app-info">
            <div className="app-icon-wrapper">
              <img src={AppIcon} alt="Morph App Icon" className="app-icon" />
            </div>
            <div className="app-text">
              <h2>Morph App on App Store / Google Play</h2>
              <p>Accelerate your inspection work</p>
            </div>
          </div>

          <div className="waitlist-form">
            <Form className="d-flex align-items-center gap-2">
              <Form.Control
                type="email"
                placeholder="Enter your email"
                size="lg"
              />
              <Button
                variant="primary"
                className="join-waitlist-btn"
                size="lg"
                onClick={() => setShowSurvey(true)}
              >
                Join Waitlist
              </Button>
            </Form>
          </div>
        </div>

        <SurveyModal show={showSurvey} onHide={() => setShowSurvey(false)} />
      </Container>
    </section>
  );
};

export default JoinWaitlist;
