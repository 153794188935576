import React from 'react';

const VideoWrapper: React.FC<{ videoUrl: string }> = ({ videoUrl }) => {
  return (
    <div className="video-wrapper">
      <video
        src={videoUrl}
        autoPlay
        controls
        loop
        muted
        playsInline
        className="features-video"
      />
    </div>
  );
};

export default VideoWrapper;
