import HeroSection from './HeroSection/HeroSection';
import Features from './Features/Features';
import Pricing from './Pricing/Pricing';
import AboutUs from './AboutUs/AboutUs';
import JoinWaitlist from './JoinWaitlist/JoinWaitlist';
import BlueCurve from '../../assets/images/blue-curve-2x.png';

import React from 'react';

const MainContent: React.FC = () => {
  return (
    <main className="main-content">
      <HeroSection />
      <div className="blue-curve-wrapper">
        <img src={BlueCurve} alt="Decorative blue curve" />
      </div>
      <Features />
      <div className="blue-curve-wrapper">
        <img src={BlueCurve} alt="Decorative blue curve" />
      </div>
      <Pricing />
      <AboutUs />
      <JoinWaitlist />
    </main>
  );
};

export default MainContent;
