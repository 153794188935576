import React from 'react';
import { Container, Button } from 'react-bootstrap';
import './pricing.css';

const PricingCard = ({
  title,
  price,
  features,
}: {
  title: string;
  price: string;
  features: string[];
  isBasic?: boolean;
}) => (
  <div className="pricing-card">
    <h3 className="pricing-card-title">{title}</h3>
    <div className="pricing-amount">
      <span className="price">${price}</span>
      <span className="period">/month</span>
    </div>
    <ul className="pricing-features">
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
    <Button variant="primary" className="coming-soon-btn">
      <span style={{ fontWeight: 700 }}>Coming Soon</span>
    </Button>
  </div>
);

const Pricing = () => {
  const basicFeatures = [
    'AI-assisted report generation',
    'Real-time report building',
    'Voice-to-text functionality',
    'Photo annotation features',
    'Usage limit: 10 reports/month',
  ];

  const premiumFeatures = ['All Basic features', 'Unlimited report usage'];

  return (
    <section className="pricing" id="pricing">
      <Container>
        <h2 className="pricing-title">Pricing Plans</h2>
        <div className="pricing-cards-wrapper">
          <PricingCard title="Basic" price="9.99" features={basicFeatures} />
          <PricingCard
            title="Premium"
            price="19.99"
            features={premiumFeatures}
            isBasic={false}
          />
        </div>
      </Container>
    </section>
  );
};

export default Pricing;
