import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import './heroSection.css';
import HeroImageLeft from '../../../assets/images/hero-image-left.png';
import HeroImageRight from '../../../assets/images/hero-image-right.png';

const HeroSection = () => {
  return (
    <section className="hero">
      <div className="hero-inner">
        <Row>
          {/* Images Column (1-6) */}
          <Col xxl={6} xl={6} md={4} className="position-relative order-lg-1">
            <div className="hero-images">
              <div className="image-container">
                <img
                  src={HeroImageLeft}
                  alt="App Screenshot 1"
                  className="phone-left"
                />
                <img
                  src={HeroImageRight}
                  alt="App Screenshot 2"
                  className="phone-right"
                />
              </div>
            </div>
          </Col>

          {/* Text Content Column (7-12) */}
          <Col xxl={6} xl={6} md={8} className="hero-text-column order-lg-2">
            <div className="hero-content">
              <div className="text-wrapper">
                <h1 className="hero-title">
                  <span className="small-title">From Photos to Reports</span>
                  <span className="d-block  bold-title">AI at Your</span>
                  <span className="d-block  bold-title big-title">Service</span>
                </h1>
              </div>

              <div className="form-wrapper">
                <Form className="email-signup">
                  <Row className="g-2">
                    <Col xl={8} lg={12}>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        size="lg"
                      />
                    </Col>
                    <Col xl={4} lg={12} className="join-waitlist-btn-wrapper">
                      <Button
                        variant="primary"
                        className="join-waitlist-btn"
                        size="lg"
                      >
                        Join Waitlist
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HeroSection;
